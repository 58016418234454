import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; // استيراد Google Analytics
import Home from './pages/Home';
import Checkout from './pages/component/Checkout';
import AddToCart from './pages/component/AddToCart';
import CreatePost from './pages/post/CreatePost';
import Portfolio from './pages/component/Portfolio';
import OrderConfirmation from './pages/component/OrderConfermation';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import AddToCartSold from './pages/component/AddToCardSold';

// إعداد Google Analytics
ReactGA.initialize('GTM-WZNM5X4N'); // استبدل بـ Measurement ID الخاص بك

const AppRoutes = () => {
  const location = useLocation();
  const [post,setPost] = useState([])

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // تتبع كل زيارة صفحة
  }, [location]);
  const [inpValue,setValue] = useState(1)
  const [loading,setloading] = useState(true)
  
  
  useEffect(() => {
    // العثور على العنصر المحدد بالـ class "Product"
    const productDiv = document.querySelector('.app');

    // منع القائمة التي تظهر عند الضغط على الزر الأيمن
    const handleContextMenu = (event) => {
      event.preventDefault(); // منع السلوك الافتراضي
    };

    // إضافة الحدث "contextmenu"
    if (productDiv) {
      productDiv.addEventListener('contextmenu', handleContextMenu);
    }

    // تنظيف الحدث عند إلغاء التثبيت
    return () => {
      if (productDiv) {
        productDiv.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, []);

  

  return (
    <div className='app'>
      <Routes >
      <Route path='/' element={<Home post={post} setPost={setPost} inpValue={inpValue} setValue={setValue} />} /> {/* ��ذا لزم الأمر �� أضف ��فحة الر��يسية هنا */}
      <Route path='*' element={<Home post={post} setPost={setPost} inpValue={inpValue} setValue={setValue} />} />
      <Route path='/post/:postId' element={<Home post={post} setPost={setPost} inpValue={inpValue} setValue={setValue} />} />
      <Route path='/Add-to-Cart/:postId' element={<AddToCart post={post} setPost={setPost} inpValue={inpValue} setValue={setValue} />} />
      <Route path='/Add-to-Cart-Sold' element={<AddToCartSold post={post} setPost={setPost} inpValue={inpValue} setValue={setValue} />} />
      <Route path='/Checkout' element={<Checkout  />} /> {/* أضف صفحة Checkout هنا إذا لزم الأمر */}
      {/* <Route path='/create-post' element={<CreatePost />} /> أضف صفحة Checkout هنا إذا لزم الأمر */}
      <Route path='/soufiane-moutaouakil' element={<Portfolio/>} /> {/* أضف صفحة Checkout هنا إذا لزم الأمر */}
      <Route path='/order-confirmation/:orderId/:token' element={<OrderConfirmation/>} /> {/* أضف صفحة Checkout هنا إذا لزم الأمر */}
      <Route path='/about' element={<About/>} /> {/* أضف صفحة Checkout هنا إذا لزم الأمر */}
      <Route path='/Services' element={<Services/>} /> {/* أضف صفحة Checkout هنا إذا لزم الأمر */}
      <Route path='/Contact' element={<Contact/>} /> {/* أضف صفحة Checkout هنا إذا لزم الأمر */}
    </Routes>

    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
